<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? msg('Update Event'): msg('Add Event') }}
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ invalid }"
          ref="refFormObserver"
        >
          <!-- Form -->
          <div class="p-2">
            <!-- Title -->
            <validation-provider #default="validationContext" :name="msg('Title')" rules="required">
              <b-form-group :label="msg('Title')" label-for="event-title">
                <b-form-input
                  id="event-title"
                  v-model="eventLocal.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Calendar -->
            <validation-provider
              #default="validationContext"
              name="msg('Calendar')"
              rules="required"
            >
              <b-form-group
                :label="msg('Calendar')"
                label-for="calendar"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="eventLocal.type"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  :options="Object.values(types)"
                  label="name"
                  :reduce="type => type.id"
                  :clearable="false"
                >
                  <template #option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ color, name }">
                    <div
                      class="rounded-circle d-inline-block mr-50"
                      :class="`bg-${color}`"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>


            <!-- Start Date -->
            <validation-provider
              #default="validationContext"
              name="Start Date"
              rules="required"
            >

              <b-form-group
                :label="msg('Start Date')"
                label-for="start-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- End Date -->
            <validation-provider
              #default="validationContext"
              name="End Date"
              rules="required"
            >

              <b-form-group
                :label="msg('End Date')"
                label-for="end-date"
                :state="getValidationState(validationContext)"
              >
                <flat-pickr
                  v-model="eventLocal.end"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Event URL -->
            <validation-provider
              #default="validationContext"
              name="Event URL"
              rules="url"
            >

              <b-form-group
                :label="msg('Event URL')"
                label-for="event-url"
              >
                <b-form-input
                  id="event-url"
                  v-model="eventLocal.url"
                  type="url"
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>



            <!-- Users -->
            <b-form-group
              :label="msg('representatives')"
              label-for="add-guests"
            >
              <v-select
                v-model="eventLocal.usersIds"
                :dir="isRTL ? 'rtl' : 'ltr'"
                multiple
                :close-on-select="false"
                :options="otherUsers"
                label="name"
                :reduce="(us) => us.id"
              >

                <template #option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    :src="avatar"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                    size="sm"
                    class="border border-white"
                    :src="avatar"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
<!--              <b-form-checkbox
                  v-model="selected"
                  value="A"
                  class="custom-control-primary mt-1"
              >
                {{msg("Only them")}}
              </b-form-checkbox>-->
            </b-form-group>

            <!-- Client -->
            <b-form-group
                :label="msg('clients')"
                label-for="add-guests"
            >
              <v-select
                  v-model="eventLocal.clientsIds"
                  :dir="isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :close-on-select="false"
                  :options="[]"
                  label="name"
                  :reduce="(us) => us.id"
              >

                <template #option="{ avatar, name }">
                  <b-avatar
                      size="sm"
                      :src="avatar"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>

                <template #selected-option="{ avatar, name }">
                  <b-avatar
                      size="sm"
                      class="border border-white"
                      :src="avatar"
                  />
                  <span class="ml-50 align-middle"> {{ name }}</span>
                </template>
              </v-select>
            </b-form-group>

            <!-- Location -->
            <b-form-group
              :label="msg('Location')"
              label-for="event-location"
            >
              <b-form-input
                id="event-location"
                v-model="eventLocal.location"
                trim
              />
            </b-form-group>

            <!-- Textarea -->
            <b-form-group
              :label="msg('Description')"
              label-for="event-description"
            >
              <b-form-textarea
                id="event-description"
                v-model="eventLocal.body"
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="invalid"
                @click="save"
              >
                {{ eventLocal.id ? msg('Update') : msg('Add') }}
              </b-button>
            </div>
          </div>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email, url } from '@validations'

import { mapActions, mapGetters } from 'vuex'
import useCalendarEventHandler from '@/views/calendar/calendar-event-handler/useCalendarEventHandler'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CalendarEventHandler',
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    eventId: {
      type: Number,
      required: true,
      default: ()=>0,
    },
    defaultDate:{
      type: [String,null],
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  watch:{
    eventId:{
     handler(){
       this.refresh();
     },
     immediate:true
    }
  },
  data() {
    return { required, email, url,loaded:false, eventLocal:{} }
  },
  created() {

  },
  computed:{
    ...mapGetters('calendar', { types: 'getTypes' }),
    ...mapGetters('data', ['getOtherUsers','getAllBranches']),
    otherUsers() {
      return this.getOtherUsers.map(us => ({
        id: us.id,
        name: (us.firstname + ' ' + us.lastname)
      }))
    },
    allBranches(){
      return Object.values(this.getAllBranches);
    },
  },
  methods:{
    ...mapActions('calendar', {getCalendar:'get',_updateEvent:'update',_registerEvent:'register'}),
    getEmptyEvent(){
      let $this = this;
      let start = this.$moment(this.defaultDate || Date.now()).format('YYYY-MM-DD HH:mm');
      let end = this.$moment(start,'YYYY-MM-DD HH:mm').add(1, 'hours').format('YYYY-MM-DD HH:mm');
      return { branchId: 0, clientsIds: [], end: end, start: start, location: null, status: 0, title: null, type:Object.values($this.types)[0].id , url: null,  usersIds: [], body: null }
    },
    refresh(){
      console.log('Calendar Event Refreshing',this.eventId)
      if(this.eventId <= 0) {
        this.eventLocal = this.getEmptyEvent();
        this.loaded = true;
      }else this.reloadEvent();
    },
    reloadEvent(){
      let $this = this;
      this.getCalendar({id:this.eventId}).then(
          (event)=>{
            $this.eventLocal = event;
            $this.loaded = true;
          },
          ()=>{}
      )
    },
    save(){
      if(this.eventLocal.id > 0)
        this.updateEvent()
      else
        this.registerEvent()
    },
    registerEvent(){
      let $this = this;
      this._registerEvent({event:this.eventLocal}).then(
          (id)=>$this.$emit("created",id)
      ).finally(()=>$this.$root.$emit('crm::calendar::refresh'))
    },
    updateEvent(){
      let $this = this;
      this._updateEvent({id:$this.eventLocal.id,event:this.eventLocal}).finally(()=>$this.$root.$emit('crm::calendar::refresh'))
    },
  },
  setup(props) {

    const {
      refFormObserver,
      getValidationState,
    } = formValidation({}, props.clearEventData)


    return {
      // Form Validation
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">

</style>
