<template>
  <div class="app-calendar overflow-hidden border">
    <div class="row no-gutters">

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div v-if="isMonth" class="card-body pb-0">
            <treasure-calendar-month default-type="month"
                                     ref="monthCalendar"
                                     :currentMonth.sync="currentMonth"
                                     v-bind:types="selectedCalendarsIds"
                                     @event-selected="onEventSelected"
                                     @onSelect="onSelection"
                                     :property-id="propertyId"/>
          </div>
          <div v-else class="card-body pb-0">
            <treasure-calendar-week default-type="month"
                                    :currentMonth.sync="currentMonth"
                                    v-bind:types="selectedCalendarsIds"
                                    @event-selected="onEventSelected"
                                    @onSelect="onSelection"
                                    :property-id="propertyId"/>
          </div>
        </div>
      </div>

      <!--      &lt;!&ndash; Sidebar Overlay &ndash;&gt;
            <div class="body-content-overlay" :class="{'show': isCalendarOverlaySidebarActive}"
                 @click="isCalendarOverlaySidebarActive = false"/>
            <calendar-event-handler v-model="isEventHandlerSidebarActive" v-bind:event-id="event.id"
                                    @created="id=>event.id=id" :clear-event-data="clearEventData"/>-->
    </div>
    <b-modal size="lg" ref="modal-options" centered hide-footer :title="selection.property">
      <b-row v-if="selection">
        <b-col class="text-center"> {{ msg('Selected date range') }}: {{ selection.start| moment('DD.MM.YYYY', true) }}
          -
          {{ selection.end | moment('DD.MM.YYYY', true) }}
        </b-col>
      </b-row>
      <div class="text-center mt-1">
        <b-button size="sm" variant="danger" class="d-inline-block mr-2 mb-1" @click="openModal('onBlockCalendar')">
          <span class="text-nowrap">{{ msg('blockCalendar') }}</span>
        </b-button>
        <b-button size="sm" variant="primary" class="d-inline-block mr-2 mb-1"
                  @click="openModal('onTreasureContractGenerateMulti')">
          <span class="text-nowrap">{{ msg('Create contract') }}</span>
        </b-button>
<!--        <b-button size="sm" variant="dark" class="d-inline-block mb-1" @click="openModal('onTreasureContractGenerate')">
          <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
        </b-button>-->
        <b-row class="mt-3">
          <b-input-group class="w-100">
            <b-form-input :placeholder="msg('Enter price for given dates')" v-model="selection.price" debounce="500"
                          type="number"/>
            <b-input-group-append>
              <b-button size="sm" variant="success" class="d-inline-block" @click="savePrices">
                <span class="text-nowrap">{{ msg('Save prices') }}</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>


        </b-row>
      </div>
    </b-modal>
    <treasure-contract-rent-modals ref="treasure-contract-modals"/>

  </div>
</template>

<script>
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import {mapGetters} from 'vuex'
import TreasureCalendarMonth from "@/components/widgets/calendar/treasureCalendarMonth";
import TreasureCalendarWeek from "@/components/widgets/calendar/treasureCalendarWeek";
import TreasureContractRentModals from "@/components/modals/treasureContractRentModals.vue";


export default {
  name: "calendarRent",
  components: {
    TreasureContractRentModals,
    TreasureCalendarMonth,
    TreasureCalendarWeek,
    // eslint-disable-next-line vue/no-unused-components
    CalendarEventHandler,
  },
  data() {
    return {
      selection: {start: null, end: null, property: null, price: null},

      isCalendarOverlaySidebarActive: false,
      isEventHandlerSidebarActive: false,
      currentMonth: null,
      selectedCalendarsIds: [],
      event: {id: 0},
    }
  },
  props: {
    propertyId: {
      type: String,
      required: true,
    }, isMonth: {
      type: Boolean,
      required: true,
    }
  },
  computed: {
    ...mapGetters('user', {_myUserId: 'myId'}),
    ...mapGetters('calendar', ['canReadAllCalendar']),

  },
  watch: {
    isCalendarOverlaySidebarActive() {
      this.$root.$emit('bv::hide::popover')
    },
  },
  methods: {
    addNewEvent() {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onDaySelected(day) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = day;
      this.event['id'] = 0;
      this.isEventHandlerSidebarActive = true;
    },
    onEventSelected(eventId) {
      this.$root.$emit('bv::hide::popover')
      this.event['start'] = null;
      this.event['id'] = eventId;
      this.isEventHandlerSidebarActive = true;
    },
    openModal(modalMethod) {
      //console.log('openModal', modalMethod)
      this.$refs['modal-options'].hide()
      let data = this.$refs['monthCalendar'].getSelection()
      console.log('openModal', this.propertyId)
      this.$refs['treasure-contract-modals'][modalMethod](this.propertyId, data.start, data.end)
    },
    savePrices() {
      this.$refs['modal-options'].hide()
      let data = this.$refs['monthCalendar'].getSelection()
      data.price = this.selection.price
      console.log('savePrices', data)
      let request = {
        realEstateId: this.propertyId,
        start: data.start,
        end: data.end,
        price: data.price
      }
      this.saveStaticPrices(request).then(() => {
        this.$root.$emit('toast', {type: 'success', message: this.msg('Prices saved')})
      }).catch(() => {
        this.$root.$emit('toast', {type: 'error', message: this.msg('Prices not saved')})
      })
    },
    onSelection(data) {
      const {start, end, property, propertyId} = data
      this.selection = {
        start,
        end,
        propertyId,
        property: (`${property?.groupId != null ? property?.groupId : ""} ${property?.title}`).trim(),
      };
      this.$refs['modal-options'].show();
      /*  this.propertyFilterList = data.propertyFilterList
        this.allProperties = data.allProperties*/
    },
    clearEventData() {

    }
  }

}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
