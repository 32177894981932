<template>
  <div class="calendar">
    <div class="calendar" style="height: 250px">
      <FullCalendar ref="calendar" v-bind:options="calendarOptions">
        <template v-slot:eventContent='arg'>
          <span v-if="arg.event.extendedProps.totalDays <= 1" class="fc-daygrid-event-dot d-inline-block" :style="`border-color: ${arg.event.borderColor}`"></span>
          <div class="d-inline-block" v-bind:title="arg.event.title">
            {{arg.event.title}}
          </div>
        </template>
      </FullCalendar>
    </div>
    <template v-if="dialogs.popovers">
      <b-popover v-for="event in events" v-bind:key="event.id" :ref="'popover_cal_'+event.id" :target="'ev-'+event.id"
                 @show=" $root.$emit('bv::hide::popover');"
                 triggers="click focus" size="xl"
                 style="max-width: none">
        <template slot="title">
          <b-row>
            <b-col cols="9">
              {{ event.title }}
            </b-col>
            <b-col cols="3">
              <div class="d-flex align-items-center justify-content-end">

                <span class="mr-1"></span>
                <b-link style="color: inherit;" @click="closePopover" :title="msg('Close')">
                  <feather-icon icon="XIcon"/>
                </b-link>
              </div>
            </b-col>
          </b-row>

        </template>
                <div  :temp="'ev-'+event.id">
                  <div class="event-popover-content">
                    <treasureCalendarEvent :event="event" @clientSelect="onClientSelect"/>
                  </div>
                </div>
      </b-popover>
    </template>
  </div>
</template>
<script>

import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import allLocales from '@fullcalendar/core/locales-all'
import {mapActions, mapGetters} from 'vuex'
import calendarEvent from '@/components/widgets/calendar/calendarEvent'
import listPlugin from '@fullcalendar/list'
import treasureCalendarEvent from "@/components/widgets/calendar/treasureCalendarEvent";

export default {
  components: {
    FullCalendar,
    // eslint-disable-next-line vue/no-unused-components
    treasureCalendarEvent
  },
  data() {
    return {
      calendarOptions: {
        eventContent: this.addLogoToEvent,
        displayEventTime: false,
        showNonCurrentDates: true,
        locales: allLocales,
        locale: 'de',//this.currentLang, // the initial locale
        plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridWeek',
        },
        initialView: 'dayGridWeek',
        dateClick: this.onDateClick,
        editable: true,
        allDaySlot: false,
        eventDrop: this.moveEvent,
        events: this.onCalendarRefresh,
        eventClick: this.onEventClick,
        eventDidMount: this.onEventDidMount,
      },

      events: [],
      cal: {start: '', end: ''},
      loadedExternalEvents: {},
      dialogs: {
        popovers: false
      },
      listeners: {
        refresh() {
        }
      }
    }
  },
  props: {
    types: Array,
    propertyId: {
      type: String,
      required: true,
    },
  },
  watch: {
    currentLang: {
      handler() {
        this.calendarOptions.locale = this.currentLang
      },
      immediate: true
    },
    types: {
      handler() {
        console.log('types watcher triggered');
        this.refreshCalendar()
      },
      // immediate: true
    },
  },
  created() {
    console.log('calendar created and listener attached');
    let $this = this;
    this.$root.$on('crm::calendar::refresh', this.listeners.refresh = function () {
      $this.refreshCalendar()
    })
  },
  beforeDestroy() {
    this.$root.$off('crm::calendar::refresh')
  },
  computed: {
    ...mapGetters('user', ['myId']),
    ...mapGetters('translator', {currentLang: 'getCurrentLang'}),
  },
  methods: {
    ...mapActions('calendar', ['getExternalCalendars', 'getTreasureCalendars', 'update']),
    ...mapActions('calendar', {_dismiss: 'dismiss'}),
    ...mapActions('clients', {_loadClientData: 'loadData'}),
    addLogoToEvent(arg) {
      if (arg.event.startStr === '2023-09-02') {  // Replace with your desired date
        let arrayOfDomNodes = [];
        let imgElement = document.createElement('img');
        imgElement.src = 'https://www.google.com/url?sa=i&url=https%3A%2F%2Fstock.adobe.com%2Fsearch%3Fk%3Dwhite%2Btiger&psig=AOvVaw3DNQ9UYiVSFtVWGU-0neIH&ust=1694725485047000&source=images&cd=vfe&opi=89978449&ved=0CBAQjRxqFwoTCLiBkMa-qIEDFQAAAAAdAAAAABAE';  // Replace with your logo's path
        imgElement.style.width = '20px'; // Adjust as necessary
        imgElement.style.height = '20px'; // Adjust as necessary
        arrayOfDomNodes.push(imgElement);
        return {domNodes: arrayOfDomNodes};
      }
    },
    refreshCalendar() {
      console.log('refreshCalendar')
      let calendarApi = this.$refs.calendar.getApi()
      calendarApi.refetchEvents()

    },
    onCalendarRefresh(info, successCallback, failureCallback) {
      console.log('onCalendarRefresh')
      this.cal.start = this.$moment(info.start).startOf('month')
          .format('YYYY-MM-DD HH:mm')
      this.cal.end = this.$moment(info.end).endOf('month')
          .format('YYYY-MM-DD HH:mm')
      this.refresh()
          .then(() => successCallback(this.adaptEvents()), failureCallback)
      /* this.reloadExternalEvents(this.$moment(info.start)
           .format('MM'), this.$moment(info.start)
           .format('YYYY'))*/
    },
    refresh() {
      let $this = this
      return new Promise((resolve, reject) => {
        this.getTreasureCalendars({
          filter: {
            from: $this.cal.start,
            until: $this.cal.end,
            userid: -1,
            typeList: [6, 7],
            propertyId: this.propertyId
          }, prices: true
        })
            .then(
                (events) => {
                  $this.updateEvents(events)
                  resolve()
                },
                reject
            )
      })
    },
    updateEvents(apiEvents) {
      if (!Array.isArray(apiEvents)) {
        apiEvents = []
      }

      this.events = apiEvents.map(e => ({...e, external: false}));
      console.log("updateEvents", this.events)

      return this.events
    },
    adaptEvents() {
      console.log("adaptEvents");
      let $this = this;
      return this.events.map(ev => ({
        start: ev.start,
        end: ev.end,
        title: ev.title,
        id: 'ev-' + ev.id,
        rawId: ev.id,
        totalDays:  $this.$moment(ev.end)
            .diff($this.$moment(ev.start), 'days'),
        color: this.getColor(ev),
      }))
    },
    getColor(event) {
      if (event == null ||  event.platform == null)
        return 'rgba(199,166,128,0.44)';

      let platform = event.platform;
      if (platform === 'Booking')
        return "rgba(49,121,231,0.27)"

      if (platform === 'Expedia')
        return "rgba(239,221,9,0.5)"

      if (platform === 'Airbnb')
        return "rgba(255,82,88,0.57)"

      if (platform === 'Vrbo')
        return "#dfe6e9"

      if (platform === 'Agoda')
        return "#2ecc71"

      if (platform === 'Treasure Home')
        return "#c7a680"

      if (platform === 'Unknown')
        return "rgba(236,228,207,0.73)";


    },
    eventsFilter(ev) {
      return (!Array.isArray(this.types) || this.types.includes(ev.type));
    },
    reloadExternalEvents(month, year) {
      if (this.loadedExternalEvents[month + '-' + year] !== true) {
        this.getExternalCalendars({
          month,
          year
        })
            .then(extEvents => {
              this.loadedExternalEvents[month + '-' + year] = true
              if (!Array.isArray(extEvents)) {
                return
              }

              extEvents.forEach(ext => {
                let event = {
                  id: (ext.subcat + '_' + ext.date),
                  date: ext.date,
                  title: ext.title,
                  src: ext.link,
                  body: ext.memo,
                  external: true,
                  color: '#000'
                }
              /*  if (ext['yomtov'] == true) {
                  event.color = '#9e0000'
                }
                if (ext['subcat'] == 'fast') {
                  event.color = '#69819e'
                }*/
                if (this.events.findIndex(ev => event.id === ev.id) >= 0) {
                  return
                }
                this.events.push(event)
              })
            })
      }
    },
    onEventDidMount: function (eventInfo) {
      let $this = this;
      eventInfo.el.id = eventInfo.event.id

      eventInfo.el.ondblclick = function () {
        $this.editEvent(eventInfo.event.extendedProps.rawId)
      }
      this.refreshPopovers()
    },
    refreshPopovers() {
      let $this = this
      //Hack in order to reload the popovers
      this.dialogs.popovers = false
      setTimeout(() => {
        this.dialogs.popovers = true
        $this.$forceUpdate()
      }, 1000)
    },
    onDateClick(arg) {
      this.$emit('day-selected', arg.dateStr)
    },
    moveEvent(info) {
      let eventInfo = info.event
      if (!confirm('Are you sure about this change?')) {
        info.revert()
        return
      }
      let event = this.events.find(e => e.id === parseInt(eventInfo.id.substr(3)))
      if (event == null) {
        info.revert()
        return
      }
      event = {...event}
      event.start = this.$moment(eventInfo.start)
          .format('YYYY-MM-DD HH:mm')
      event.end = this.$moment(eventInfo.end)
          .format('YYYY-MM-DD HH:mm')
      this.update({
        id: event.id,
        event: event
      })
      this.refreshPopovers()
    },
    editEvent(id) {
      this.$emit('event-selected', id)
      this.closePopover()
    },
    onEventClick(eventInfo) {
      //this.editEvent(eventInfo.event)
      this.$root.$emit('bv::show::popover', 'ev' + eventInfo.event.id)
    },
    closePopover(popover) {
      if (popover != null && typeof popover === 'string') {
        this.$root.$emit('bv::hide::popover', popover)
      } else {
        this.$root.$emit('bv::hide::popover')
      }

    },
    onClientSelect(id) {
      console.log('calendar-> onClientSelect(' + id + ')')
      this.$root.$emit('crm::client::select', id)
    },
    dismissEvent(id) {
      let $this = this
      this._dismiss({eventId: id})
          .then(
              () => {
                //remove event
                let index = $this.events.findIndex(e => e.id == id)
                $this.events.splice(index, 1)
                $this.onEventSubmitted()
              }
          )
    },
    onModalHidden() {

    }
  }
}
</script>

<style>
.event-popover-content {
  max-width: none;
  width: 400px;
}


/*
.fc-daygrid .fc-day-other {
  background-color: rgba(241, 145, 145, 0.24);
}
*/


.fc-event:hover {
  transform: scale(1.1);
  transition: transform 0.3s;
}


</style>
