<template>
  <div class="calendar-container">
    <div>
      <div v-if="isLoading" class="loading-container">
        <!-- Display a loading message with 3D effect -->
        <div class="loading-text">
          <span>Loading...</span>
        </div>
      </div>

      <div v-else>
        <b-card v-if="allProperties!=null &&allProperties.length>0" no-body class="calendar-search-container">
          <b-row class="mt-1">
            <b-col cols="8">
              <b-button variant="danger" size="sm" class="d-inline-block mr-2 mb-1"
                        @click="$refs['treasure-contract-modals'].onBlockCalendar()">
                <span class="text-nowrap">{{ msg('block Calendar') }}</span>
              </b-button>

              <b-button variant="success" size="sm" class="d-inline-block mr-2 mb-1"
                        @click="$refs['treasure-contract-modals'].onUnBlockCalendar()">
                <span class="text-nowrap">{{ msg('unblock Calendar') }}</span>
              </b-button>
              <b-button variant="primary" size="sm" class="d-inline-block mr-2 mb-1"
                        @click="$refs['treasure-contract-modals'].onTreasureContractGenerateMulti()">
                <span class="text-nowrap">{{ msg('Create contract') }}</span>
              </b-button>
              <!--              <b-button variant="dark"  size="sm" class="d-inline-block mb-1" @click="$refs['treasure-contract-modals'].onTreasureContractGenerate()">
                              <span class="text-nowrap">{{ msg('Generate treasure rent') }}</span>
                            </b-button>-->
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <!-- Branch Selection -->
            <b-col cols="12" md="6" lg="3">
              <b-form-group :label="msg('Branch')">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="buidsList"
                    placeholder="Branch"
                    :options="allBranches"
                    class="w-100"
                    label="name"
                    multiple
                    :reduce="(branch) => branch.id"
                    :clearable="false"
                />
              </b-form-group>
            </b-col>

            <!-- Property Selection, filtered by selected Branch -->
            <b-col cols="12" md="6" lg="4">
              <b-form-group :label="msg('Properties')">
                <v-select
                    :dir="isRTL ? 'rtl' : 'ltr'"
                    v-model="propertyFilterList"
                    placeholder="Property"
                    :options="filteredProperties"
                    class="w-100"
                    multiple
                    label="address"
                    :reduce="(property) => property.id"
                    :clearable="false"
                    :readonly="buidsList.length === 0"
                >
                  <template slot="option" slot-scope="option">
          <span>
            {{ option.address }} {{ option.house }} / {{ option.apt }}, {{ option.city }},
            {{ option.country }}
          </span>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <!-- Reset Button -->
            <b-col cols="12" md="6" lg="2">
              <b-button
                  size="sm"
                  variant="primary"
                  style="margin-top: 2.5vh"
                  @click="resetFilters"
              >
                {{ msg('reset') }}
              </b-button>
            </b-col>

            <!-- Week <> Month Switch -->
            <b-col cols="12" md="6" lg="2">
              <div class="mt-1">
                <b-form-group :label="msg('Week <> Month')">
                  <b-form-checkbox v-model="isMonth" name="check-button" switch></b-form-checkbox>
                </b-form-group>
              </div>
            </b-col>
          </b-row>

        </b-card>

        <div class="mt-2" :style="{ border: '1px solid grey' }">
          <!-- Your content goes here -->
        </div>
        <p class="mt-2">Filter for free dates</p>
        <b-row v-if="allProperties!=null &&allProperties.length>0" class="mt-1">
          <b-col cols="12" md="6" lg="5">
            <b-form-group>

              <calendar-picker-range v-model="datesRange"/>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="6" lg="2">
            <b-button variant="primary" @click="datesRange=null">{{ msg('reset') }}
            </b-button>
          </b-col>

          <template v-if="properties!=null">
            <b-col cols="12" md="6" lg="3">
              <b-pagination
                  v-model="page.currentPage"
                  :total-rows="!isFiltered?properties.length:displayedProperties.length"
                  :per-page="page.amount"
                  align="center"
                  size="md"
              ></b-pagination>
            </b-col>
            <b-col cols="12" md="6" lg="2" style="margin-top: 1vh"><span>Total angezeigte Kalendar:
            {{
                isFiltered ? (allFilteredProps == null ? 0 : allFilteredProps.length) : allProps.length
              }}/ {{ properties.length }}</span>
            </b-col>
          </template>
        </b-row>


        <b-row class="mt-1" v-if="!isFiltered">
          <div v-for="(property, id) in allProps" :key="id" class="col-md-6 mb-2">
            <b-card no-body class="property-card-with-shadow" style="height: 100%">
              <b-row no-gutters>
                <b-col cols="12">
                  <b-card-body>
                    <h4 class="calendar-title customColor">
                      {{ property.address }} {{ property.house }} / {{ property.apt }}, {{ property.city }},
                      {{ property.country }}
                    </h4>
                    <calendar-rent :property-id="property.id" :key="property.id" :is-month="isMonth"/>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-row>
        <b-row v-else class="mt-1">
          <div v-for="(property, id) in allFilteredProps " :key="id" class="col-md-6 mb-2">
            <b-card no-body class="property-card-with-shadow">
              <b-row no-gutters>
                <b-col cols="12">
                  <b-card-body>
                    <h4 class="calendar-title customColor">
                      {{ property.address }} {{ property.house }} / {{ property.apt }}, {{ property.city }},
                      {{ property.country }}
                    </h4>
                    <calendar-rent :property-id="property.id" :key="property.id" :is-month="isMonth"/>
                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </b-row>
      </div>

    </div>
    <treasure-contract-rent-modals ref="treasure-contract-modals"/>
  </div>
</template>


<script>
import CalendarRent from "@/views/calendar/CalendarRent";

import {mapActions, mapGetters} from "vuex";
import CalendarPickerRange from "@/components/widgets/calendar/calendarPickerRange";
import TreasureContractRentPanel from "@/views/realEstate/contracts/treasureContractRentPanel";
import TreasureContractRentModals from "@/components/modals/treasureContractRentModals.vue";

export default {
  name: "calendarRentSite",
  components: {
    TreasureContractRentModals,
    // eslint-disable-next-line vue/no-unused-components
    CalendarRent, CalendarPickerRange, TreasureContractRentPanel
  },
  data() {
    return {
      //propertyId:'64afbcd185723c369259c981',
      datesRange: null,
      savingContract: false,
      isMonth: true,
      page: {
        amount: 10, // Number of rows per page
        currentPage: 1, // Current page
        total: 0, // Total number of rows (set to 0 initially)
      },
      propertyId: null,
      dialogs: {treasureContract: false, blockCalendar: false, treasureContractMulti: false},
      buidsList: [],
      propertyFilterList: null,
      allProperties: null,
      isLoading: true, // Initial loading state
      selectedProperty: null, // Selected property ID
      properties: null,
      displayedProperties: null,
      filter: {
        like: {search: '', name: "", shortDescription: ""},
        eq: {type: "", realEstateArea: {}, featureStatus: null},
        lte: {realEstatePrices: {}},
        gte: {realEstatePrices: {}},
        in: {}
      },
    }
  },
  created() {
    console.log('calendar site created getting real estates')
    this._getRealEstates({amount: 9999, filter: this.filter, buids: this.buidsList, propIds: this.propertyFilterList})
        .then((ans) => {
          this.allProperties = ans['RealEstates'];
          this.properties = ans['RealEstates'];
        })
        .catch(() => console.log('Error getting real estates from db'));
  },
  mounted() {
    // Simulate an asynchronous operation (replace this with your actual data loading)

    setTimeout(() => {
      this.isLoading = false; // Set loading state to false when components are done loading
    }, 1000); // Replace 2000 with the actual time your components take to load
  },
  computed: {
    ...mapGetters('data', ['getAllLanguage', 'getAllBranches', 'getAllCountries', 'getAdminRoleId']),
    isFiltered() {
      return this.datesRange != null && this.datesRange.start != null && this.datesRange.end != null;
    },
    filteredProperties() {
      if (this.buidsList.length === 0) {
        return []; // No branches selected, return empty array
      }
      // Filter properties that belong to the selected branches
      return this.allProperties.filter(property =>
          this.buidsList.includes(property.buid)  // 'buid' is the field that links property to branch
      );
    },
    allBranches() {
      return Object.values(this.getAllBranches)
    },
    allProps() {
      const startIndex = (this.page.currentPage - 1) * this.page.amount;
      const endIndex = startIndex + this.page.amount;

      return this.properties?.slice(startIndex, endIndex);
    },
    allFilteredProps() {
      const startIndex = (this.page.currentPage - 1) * this.page.amount;
      const endIndex = startIndex + this.page.amount;

      return this.displayedProperties?.slice(startIndex, endIndex);
    },
  },
  watch: {
    buidsList(newValue, oldValue) {
      // Check if the value has changed
      if (newValue !== oldValue) {
        // Call a method to refresh the component
        this.getAll();
      }
    }
    , propertyFilterList(newValue, oldValue) {
      // Check if the value has changed
      if (newValue !== oldValue) {
        // Call a method to refresh the component
        this.getAll();
      }
    },
    datesRange: {
      deep: true,
      handler: 'filterPropertiesByDateRange',
    },
  },
  methods: {
    ...mapActions('realEstate', {_getRealEstates: 'getRealEstates'}),
    getAll() {
      if (this.buidsList.length < 1) {
        this.buidsList = [this.allBranches[0].id];
      }

      this._getRealEstates({amount: 9999, filter: this.filter, buids: this.buidsList, propIds: this.propertyFilterList})
          .then((ans) => {
            this.properties = ans['RealEstates'];
          })
          .catch(() => console.log('Error getting real estates from db'));
    },
    selectProperty(id) {
      // Set the selected property ID and update the property ID
      this.selectedProperty = id;
      this.propertyId = id; // You can adjust it based on your logic
    },
    resetFilters() {
      this.propertyFilterList = null;
      this.datesRange = null;
      this.buidsList = [];
    },
    filterPropertiesByDateRange() {
      if (this.datesRange == null)
        return;
      const {start, end} = this.datesRange;

      if (start && end) {
        // Convert start and end to Date objects
        const startDate = new Date(start);
        const endDate = new Date(end);

        this.displayedProperties = this.properties.filter(property => {
          const hasOverlap = property.bookings ?
              property.bookings.some(booking => {
                const bookingStartDate = new Date(booking.checkInDate);
                const bookingEndDate = new Date(booking.checkOutDate);

                return startDate < bookingEndDate && endDate > bookingStartDate;
              }) : false;

          console.log('hasOverlap:', hasOverlap);

          return !hasOverlap;
        });
      }
    },


  }

}
</script>

<style scoped>
/* Styles for the calendar container */

.calendar-container {
  margin: 20px 0; /* Adjust margin as needed */
}


.property-card-with-shadow {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adjust the shadow as needed */
  transition: box-shadow 0.3s ease; /* Add a transition effect for smooth changes */
  height: auto; /* Adjust the height as needed */
}

.property-card-with-shadow:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Adjust the shadow on hover as needed */
}

.customColor {

  text-align: center;
  color: #070505;
  text-shadow: 2px 2px 5px rgba(245, 241, 232, 0.73);
}

.dark-layout .customColor {

  text-align: center;
  color: goldenrod;
  text-shadow: 2px 2px 5px #000;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-text {
  font-size: 24px;
  font-weight: bold;
  color: #c2aa8e; /* Choose your desired color */
}

.loading-text span {
  display: inline-block;
  transform-origin: 50% 50%;
  animation: bounce 1.5s infinite alternate;
}

@keyframes bounce {
  to {
    transform: translateY(-20px);
    text-shadow: 0 0 5px #c2aa8e; /* Adjust the shadow color */
  }
}

.calendar-search-container {
  position: sticky;
  z-index: 999;
  top: 10px;
  padding: 0 0.5rem;
}
</style>
